import React from 'react'
import { makeStyles,withStyles,useTheme } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import ReactPlayer from 'react-player'
import ButtonBase from '@material-ui/core/ButtonBase';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const PropertyCarousel = (props) => {
    const {banners=[],setCurrentIndex,currentIndex,onClickImage,height=600} = props
    const styles = useStyles()
    return (
            <Carousel
                swipeable
                onChange={index=>setCurrentIndex(index)}
                emulateTouch
                selectedItem={currentIndex}
                showIndicators={false}
                useKeyboardArrows={false}
                infiniteLoop={banners.length>1}
                showStatus={false}
                showThumbs={false}
                dynamicHeight={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <ButtonBase onClick={onClickHandler} className={styles.buttonText} style={{left:8}} disableTouchRipple>
                            {'‹'}
                        </ButtonBase>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <ButtonBase onClick={onClickHandler} className={styles.buttonText} style={{right:8}} disableTouchRipple>
                            {'›'}
                        </ButtonBase>
                    )
                }
            >
            {banners.map((banner,index)=>
                banner.video?
                    currentIndex===index &&
                    <ReactPlayer
                        width="100%"
                        height={height}
                        key={index}
                        url={banner.video}
                        controls
                        style={{backgroundColor:'black'}}
                    />
                :
                banner.placeholder?
                    <img src={banner.placeholder} key={index} className={styles.img} style={{height}}/>
                :
                <div key={index} onClick={onClickImage} style={{pointerEvents:'auto'}}>
                    <img src={banner.lowUrl} className={styles.img} style={{height}}/>
                </div>
            )}
            </Carousel>
    )
}

const useStyles = makeStyles((theme) => ({
    buttonText: {
        position: 'absolute',
        zIndex:1,
        fontWeight: 300,
        top:'calc(50% - 20px)',
        width:40,
        height:40,
        cursor:'pointer',
        fontSize: 70,
        color: 'white',
        textShadow: '4px 4px 10px rgba(0, 0, 0, 0.79)'
    },
    img:{
        objectFit:'cover',
    }
}));

export default PropertyCarousel
